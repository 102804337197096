<template>
  <div class="wrapper">
    <div
        class="quiz-answer quiz-answer-select"
        :class="{
          'quiz-answer-checked': isChecked,
        }"
        v-if="!isTextInput"
        @click="updateState"
    >
      <svg
          class="quiz-answer-icon"
          :class="{
          'quiz-answer-icon-checked': isChecked,
        }"
          viewBox="0 0 1 1"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M.04.627L.146.52.43.804.323.91zm.177.177L.854.167.96.273.323.91z"
              fill-rule="nonzero"
              stroke-width="0"
        />
      </svg>
      <div class="quiz-answer-title">
        {{ title || '' }}
      </div>
    </div>
    <InputAnswer
        v-else
        :state="state"
        :answer="answer"
        :answers="answers"
        :question="question"
        class="quiz-input"
        @update="updateSpecialInput"
    />
  </div>
</template>

<script>
import InputAnswer from './input';
export default {
  name: 'CheckboxAnswer',
  components: {
    InputAnswer
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    answer: {
      type: Object,
      required: true
    },
    answers: {
      type: Array,
      required: false
    },
    isChecked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isTextInput () {
      return this.answer.title.includes('(input)')
    },
    title () {
      return this.answer.title.replace('(input)', '')
    }
  },
  methods: {
    updateState() {
      let values = this.state[this.answer.property] || [];
      const value = this.answer.value;
      if (values.includes(value)) values = values.filter(v => v !== value);
      else values.push(value);

      this.$emit('update', {
        state: {
          ...this.state,
          [this.answer.property]: values,
        },
        question: this.question,
        answer: this.answer,
      });
    },
    updateSpecialInput () {
      this.$emit('update', {
        state: {
          ...this.state,
          special_field: this.answer
        },
      });
    }
  }
}
</script>

<style scoped>
@import "../../style/answer-animation-select.css";

.quiz-answer {
  display: block;
  padding: var(--space-l);
  border: 1px solid var(--color-8);
  border-radius: var(--space-s);
  margin-bottom: var(--space-l);
  cursor: pointer;
  transition: 0.3s border, 0.3s box-shadow;
  white-space: nowrap;
  box-sizing: border-box;
}
.quiz-input {
  border: none;
  box-shadow: none !important;
  padding: 0;
  display: flex;
}

.quiz-answer-checked {
  border: 1px solid var(--color-7);
  box-shadow: 1px 1px 2px var(--color-12);
}

.quiz-answer:hover {
  box-shadow: 1px 1px 2px var(--color-3);
}

.quiz-answer-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
  vertical-align: top;
  margin-right: var(--space-m);
  border: 1px solid var(--color-8);
  background-color: var(--color-8);
  fill: var(--color-8);
}

.quiz-answer-icon-checked {
  background-color: var(--color-6);
  border: 1px solid var(--color-6);
  fill: var(--color-5);
}

.quiz-answer-title {
  display: inline-block;
  font-size: var(--font-size-m);
  font-weight: 100;
  line-height: 1.3;
  text-overflow: ellipsis;
  color: var(--color-1);
  text-decoration: none;
  text-align: left;
  vertical-align: middle;
  white-space: initial;
  max-width: calc(100% - 30px);
}
</style>
