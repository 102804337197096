import http from '@/helpers/http';
import answersByQuestion from '@/helpers/mock';
import {
    QUESTION_TEMPLATES
} from './helpers/constants';

export function getQuiz() {
    return http.mock({id: 1, title: 'Выбор парковки'}, 100);
}

export function getQuestions() {
    return http.mock([
        {id: 1, title: 'Информация о вашей компании', template_id: QUESTION_TEMPLATES.INPUTS},
        {id: 2, title: 'Расскажите о вашей деятельности', template_id: QUESTION_TEMPLATES.INPUTS},
        {id: 3, title: 'С кем из производителей вы работали?', template_id: QUESTION_TEMPLATES.MULTIPLE },
        {id: 4, title: 'Деятельность компании', template_id: QUESTION_TEMPLATES.MULTIPLE},
        // {id: 5, title: 'Напишите, пожалуйста, адрес объекта', template_id: QUESTION_TEMPLATES.TEXTAREA},
        {id: 6, title: 'Форма отправки данных', template_id: QUESTION_TEMPLATES.FORM},
        {
            id: 7, title: 'Спасибо!',
            description: 'Мы уже получили ваш запрос и скоро с вами свяжемся.',
            template_id: QUESTION_TEMPLATES.NOTIFICATION
        },
    ], 100);
}

export function getAnswers(questionId) {
    return http.mock(answersByQuestion[questionId], 100);
}

export function getHelps(testId) {
    if (testId === 666) {
        return http.mock([], 100);
    }
    return http.mock([
        {
            id: 1, template_id: 1, question_id: 1,
            title: 'Специалист RPS',
            cover_url: '/quiz/assets/avatar.jpg',
            description: 'От типа объекта чаще всего зависит, какие задачи требуется решить.\nНапример, для бизнес-центров разграничить доступ для постоянных и гостей.\nА для спортивных комплексов организовать въезд по штрих-коду в билете.'
        },
        {
            id: 1, template_id: 1, question_id: 2,
            title: 'Специалист RPS',
            cover_url: '/quiz/assets/avatar.jpg',
            description: 'Это поможет подобрать оптимальный состав для вашего оборудования.'
        },
        {
            id: 1, template_id: 1, question_id: 5,
            title: 'Специалист RPS',
            cover_url: '/quiz/assets/avatar.jpg',
            description: 'Въезд и выезд осуществляются по одной полосе и через один шлагбаум.'
        },
        {
            id: 1, template_id: 1, question_id: 6,
            title: 'Специалист RPS',
            cover_url: '/quiz/assets/avatar.jpg',
            description: 'Постоянные клиенты чаще всего это арендаторы бизнес-центров или их сотрудники. Для них можно настроить систему абонементов. А для разовых посетителей или гостей может быть настроена программа лояльности.'
        },
        {
            id: 1, template_id: 1, question_id: 8,
            title: 'Специалист RPS',
            cover_url: '/quiz/assets/avatar.jpg',
            description: 'Может быть ваш объект уже есть в нашей базе и мы ранее готовили КП для Вас. Тогда его нужно будет просто обновить.'
        }
    ], 100);
}

function addCSRF(data) {
    const getElement = (name) => document.querySelectorAll(`meta[name=${name}]`)[0] || {};
    const propertyName = getElement('csrf-param').content || '';
    data[propertyName] = getElement('csrf-token').content || null;
}

function addUTM(data) {
    const url = window.location.href.replace('/?', '');
    function getParts(url) {
        return (url.split('?')[1] || '')
          .split('&')
          .map(v => v.split('='))
          .filter(v => v[1])
    }
    let parts = getParts(url);
    parts = parts.length ? parts : getParts(window.location.href)

    if (!parts.length) return;

    data.utm = {};
    parts.forEach(([key, value]) => {
        data.utm[key] = decodeURI(value);
    });
    console.log(data)
}

addUTM({})

export function sendResult(data) {
    data.page = window.location.href
    data.from_partner_landing = true
    addCSRF(data);
    addUTM(data);
    return http.post('/ru/site/send-quiz-message/', data);
}
