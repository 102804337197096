<template>
  <div
      class="quiz-answer"
  >
    <label>
      {{ title || '' }}<span v-if="isRequired">*</span>
    </label>
    <input type="text" v-model="answer.value" @blur="updateState">
  </div>
</template>

<script>

export default {
  name: 'InputAnswer',
  props: {
    state: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    answer: {
      type: Object,
      required: true
    },
    answers: {
      type: Array,
      required: false
    },
  },
  data () {
    return {
      value: ''
    }
  },
  computed: {
    isRequired () {
      return this.answer.title.includes('*')
    },
    title () {
      return this.answer.title.replace('*', '').replace('(input)', '')
    }
  },
  methods: {
    updateState() {
      this.$emit('update', {
        state: {
          ...this.state,
          [this.question.id]: this.answers
        },
        question: this.question,
        answer: this.answer,
        skip: true
      });
    }
  }
}
</script>

<style scoped>
@import "../../style/answer-animation-title.css";
.quiz-answer {
  display: flex;
  flex-direction: column;
}
.quiz-answer:not(:last-of-type) {
  margin-bottom: 15px;
}
label {
  font-size: 16px;
  color: #000000;
  line-height: 2.7;
}
input {
  background: #FAFAFA;
  border: 1px solid #DADADA;
  /*box-sizing: border-box;*/
  border-radius: 4px;
  padding: 15px;
}
span {
  color: #EE0000;
}
</style>
