import {ANSWER_TEMPLATES} from '../components/quiz/helpers/constants';

let answerId = 0;

function getAnswersMock(templateId, property, answers) {
  const allAnswers = [];

  const getAnswer = (title, value) => {
      let val = templateId === ANSWER_TEMPLATES.INPUT ? '' : value || title
      if (val.includes('(input)')) val = ''
      return {
          id: answerId++,
          title,
          property,
          template_id: templateId,
          value: val,
      }
  };
  const generator = (title, value) => {
    if (!title) return allAnswers;
    if (title instanceof Array) {
      title.forEach((item) => {
        if (item instanceof Array) allAnswers.push(getAnswer(item[0], item[1]));
        else allAnswers.push(getAnswer(item));
      });
      return generator;
    }
    allAnswers.push(getAnswer(title, value));
    return generator;
  }
  if (answers) return generator(answers)()
  return generator;
}


// quiz: id, title, description, cover_url, type_id, first_question_id
//   question: id, title, description, cover_url, template_id, next_question_id, property
//    answer: id, title, description, cover_url, template_id, property, value
// help: id, title, description, cover_url, answer_id, question_id

// type: id, title, description, cover_url
// template: id, title, description, cover_url

const refQuestionAnswer = {
  1: getAnswersMock(ANSWER_TEMPLATES.INPUT, 'home', [
    'Наименование/форма собственности: *',
    'Адрес компании (Город, улица, дом): * ',
    'Филиалы в городах (название городов) *: ',
    'Сайт:',
  ]),
  2: getAnswersMock(ANSWER_TEMPLATES.INPUT, 'work', [
    'Профиль деятельности: *',
    'Количество реализованных объектов: *',
    'Реализованные объекты по автоматизации парковки: *',
    'Названия знаковых объектов (до 5):',
  ]),
  3: getAnswersMock(ANSWER_TEMPLATES.CHECKBOX, 'partners',
    [
        'АП-ПРО',
        'АСКТ',
        'Вектор АП',
        'КардПарк',
        'Парктайм',
        'Парктек',
        'Штрих-М',
        'SkiData',
        'Scheidt&Bachmann'
    ]),
  4: getAnswersMock(ANSWER_TEMPLATES.CHECKBOX, 'partners-2',
    [
        'Количество сотрудников в компании: (input)',
        'Наличие отдела продаж',
        'Наличие проектного отдела',
        'Есть возможность осуществлять строительно-монтажные работы на объекте',
        'Есть возможность осуществлять пусконаладочные работы на объекте',
        'Есть возможность выполнять техническое обслуживание объектов',
    ]),
  // 5: getAnswersMock(ANSWER_TEMPLATES.TEXTAREA, 'address')('Город, улица и номер дома')(),
  6: getAnswersMock(ANSWER_TEMPLATES.SEND_FORM, 'email')('Некий текст')(),
};

// refQuestionAnswer[5][0].description = refQuestionAnswer[5][0].title;
// refQuestionAnswer[5][0].title = '';

export default refQuestionAnswer;
