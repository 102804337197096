<template>
  <div class="quiz-container">
    <Quiz :id="1"/>
  </div>
</template>

<script>
import quiz1 from './examples/1'
import Quiz from './components/quiz'

export default {
  name: 'App',
  components: {
    Quiz
  },
  data() {
    return {
      quiz1,
    }
  }
}
</script>

<style>
.quiz-container {
  width: 1050px;
  height: 650px;
  border: 5px solid transparent;
  border-radius: 4px;
  border-color: #e6e6e6;
  -webkit-box-shadow: 0 7px 15px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 7px 15px 0 rgb(0 0 0 / 15%);
}
</style>
